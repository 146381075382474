<template>
 <div>
   <van-search v-model="phoneNumberCode" v-on:blur="search()" shape="round" background="#f5f5f5" placeholder="请输入查询手机号码"/>

   <div class="search-list-view">
     <van-list
       v-model="loading"
       :finished="finished"
       finished-text="没有更多了"
       @load="onLoad"
       :offset="offset"
     >
       <div v-for="item in list" :key="item" :title="item" class="search-list">
         <div>
           <svg v-show="item.productCode=='MT'" t="1638945377357" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3531" width="200" height="200"><path d="M513.0752 510.8224m-450.816 0a450.816 450.816 0 1 0 901.632 0 450.816 450.816 0 1 0-901.632 0Z" fill="#FFBC42" p-id="3532"></path><path d="M863.0784 503.7056c0-52.7872-56.3712-67.0208-86.016-77.1072-29.6448-10.0864-176.0768-64.2048-176.0768-64.2048s-100.1472-163.328-190.0032-101.888c-21.8112 13.7728-18.2272 63.6416 12.032 84.5312-41.8304-7.5776-110.7968 7.9872-107.264 47.616 3.584 39.6288 58.3168 60.0576 155.2896 43.6224 16.896-3.584 40.4992 8.9088 12.032 32.9216-15.1552 10.7008-80.9984 28.928-225.1776 3.1232-102.6048-18.3808-162.816-32.2048-189.0304-38.7072a451.6864 451.6864 0 0 0-6.6048 77.2608c0 149.504 72.8064 282.0096 184.8832 364.032 22.016-6.5536 45.0048-15.104 68.608-26.112 60.5184-28.4672 42.7008-57.856 28.4672-68.096s-42.7008-50.7392 36.9152-98.3552 178.7392-90.1632 296.8064-105.0112c37.9904-5.3248 83.6608-5.9392 105.6256-7.1168 21.9648-1.2288 79.5136-13.6704 79.5136-66.5088z m-260.5056-41.8304c-15.872 0-28.7744-12.9024-28.7744-28.7744s12.9024-28.7744 28.7744-28.7744 28.7744 12.9024 28.7744 28.7744-12.8512 28.7744-28.7744 28.7744z" fill="#FFFFFF" p-id="3533"></path></svg>
           <svg v-show="item.productCode!='MT'" class="icon icon_huafeiliuliangchongzhi" aria-hidden="true">
             <use xlink:href="#icon-xianjin_huaban1"></use>
           </svg>
         </div>
         <div>
           <p>{{item.phoneNumber.replace(/(\d{3})\d*(\d{4})/,'$1****$2')}}</p>
           <span>{{item.remark.length>5?item.remark.substring(0,4)+'..':item.remark}} {{item.orderAmt}}元</span>
         </div>
         <div>
           <p v-if="item.orderState=='00000'">成功</p>
           <p v-if="item.orderState=='00001'">已下单</p>
           <p v-if="item.orderState=='00002'">支付成功</p>
           <p v-if="item.orderState=='00003'">支付失败</p>
           <p v-if="item.orderState=='00004'">充值中</p>
           <p v-if="item.orderState=='00005'">充值失败</p>
           <p v-if="item.orderState=='00006'">已退款</p>
           <span>{{item.addTime}}</span>
         </div>
       </div>
     </van-list>
   </div>
 </div>
</template>

<script>
export default {
   data() {
    return {
      list: [],
      loading: false,
      finished: false,
      phoneNumberCode:"",
      page:1,
      limit:20,
      offset:"-10"
    };
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },
  methods:{
    search(){
      this.list = [];
      this.loading = false;
      this.finished = false;
      this.page = 1;
      this.onLoad();
    },
    onLoad() {
          if(this.loading){
            return;
          }  
          this.loading = true;

            console.log("第几次加载",this.page);
            this.axios.post('/app/query/recharge', {
              page:this.page,
              limit:this.limit,
              phoneNumberCode:this.phoneNumberCode
            }).then((response) => {
              this.list = [...this.list,...response.list];
              console.log(response);
              this.loading = false;
              if(response.list.length<response.pageSize){
                this.finished = true;
              }
              this.page = this.page+1;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
      }
  }
};
</script>

<style scoped>
.img-icon {
  height: 20px;
}
.search-list-view{
  min-height: 85vh;
  background: #ffffff;
  margin: 10px;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}
.search-list-view .search-list{
  display: flex;
  margin: 20px 0;
}
.search-list-view .search-list div{
  vertical-align: middle;
}
.search-list-view .search-list div p{
  vertical-align: middle;
  margin: 3px 0;
}
.search-list-view .search-list div:nth-child(2){
  margin-left: 15px;
}
.search-list-view .search-list div:nth-child(2) span,.search-list-view .search-list div:nth-child(3) span{
  font-size: 13px;
  color: #cecece;
}
.search-list-view .search-list div:nth-child(3){
  position: absolute;
  right: 20px;
}
.search-list-view .search-list div:nth-child(3) p{
  color: #2BD67E;
  text-align: right;
}
</style>
